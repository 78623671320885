import React from 'react';
import { Link } from 'gatsby';
import Icon from '@mdi/react'
import { Button, createStyles, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Typography } from '@material-ui/core';
import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { mdiChevronRight } from '@mdi/js';
import { ArrowForward } from '@material-ui/icons';
import { containerProps, contentProps } from '../utils/constants';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    ...containerProps,
    maxWidth: 900,
    '& h1': {
      fontSize: 'calc(75px + 8 * (100vw - 0px) / 1650)'
    }
  },
  content: contentProps,
  list: {
    '& .MuiListItemIcon-root': {
      minWidth: 35
    },
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  link: {
    textDecoration: 'none'
  },
  homepage: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  }
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <Layout>
      <SEO title='404: No encontrado' />

      <div className={classes.container}>
        <Header title='Error 404' />

        <div className={classes.content}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h3' component='p'>La sentimos, no pudimos encontrar la página que estás buscando.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                La ruta solicitada no se encuentra. Puedes regresar a la <Link to='/' className={classes.homepage}>página de inicio</Link> o visitar los demás enlaces que están en la barra de navegación.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ fontWeight: 'bold' }}>Razones por las que la página solicitada no se encuentre disponible:</Typography>
              <List disablePadding className={classes.list}>
                <ListItem disableGutters>
                  <ListItemIcon>
                    <Icon path={mdiChevronRight} size={1} />
                  </ListItemIcon>
                  <ListItemText>Puede que haya cambiado de dirección (URL) o no exista.</ListItemText>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemIcon>
                    <Icon path={mdiChevronRight} size={1} />
                  </ListItemIcon>
                  <ListItemText>Es posible que no se haya escrito correctamente su URL, compruébela de nuevo para ver si es correcta.</ListItemText>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant='contained'
                color='primary'
                endIcon={<ArrowForward />}
                onClick={() => window.history.back()}
              >
                Ir atrás
              </Button>
            </Grid>

          </Grid>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;